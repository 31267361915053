<template>
  <div class="indexChart">
    <div class="echart" ref="echartDom"></div>
  </div>
</template>
<script>
// 引入基本模板
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/dataZoom");
export default {
  data() {
    return {};
  },
  mounted() {
    this.initChart();
  },
  methods: {
    initChart() {
      let getchart = echarts.init(
        this.$refs.echartDom
      );
      getchart.clear();
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        grid: {
          top: "4%",
          left: "8%",
          right: "10%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["任务1", "任务2", "任务3", "任务4", "任务5", "任务6", "任务7"],
          color: "rgba(158, 164, 186, 1)",
          axisLine: {
            lineStyle: {
              color: "rgba(158, 164, 186, 1)",
            },
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
          axisLine: {
            lineStyle: {
              color: "rgba(158, 164, 186, 1)",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(25, 92, 225, .1)",
            },
          },
          axisLabel: {
            formatter: "{value}%",
          },
        },
        series: [
          {
            data: [20, 40, 30, 60, 78, 64, 58],
            type: "line",
            smooth: true,
            areaStyle: {},
            label: {
                   normal: {
                       show: true,
                       position: 'top',
                       color: 'rgba(25, 92, 225, 1)'
                   }
               },
            itemStyle: {
                   normal: {
                       color: 'rgba(25, 92, 225, .3)',
                       lineStyle: {
                           color: 'rgba(25, 92, 225, 1)'
                       },
                   }
               },
          },
        ],
      };

      getchart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        getchart.resize();
      });
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>