<template>
  <div class="indexPage rr">
    <Header />
    <div class="banner">
      <img src="@/assets/images/indexBanner.png" />
      <div class="content">
        <h4>诺为欣麻醉模拟系统</h4>
        <p>
          诺为欣麻醉模拟系统包含几十例基于真实临床编写的病例，涵盖全身麻醉的各个方面，可以训练对几十种麻醉紧急情况的识别与处理。通过训练软件模拟各种麻醉病例场景，培训用户掌握麻醉步骤和用药用量。
        </p>
      </div>
    </div>
    <a-row class="content float">
      <a-col :span="24">
        <a-row class="info" style="border-bottom: none">
          <a-col :span="4" class="info-item">
            <router-link
              style="margin-left: 0px; margin-top: 18px"
              to="/study"
              class="route-link-view"
              >学习记录与统计</router-link
            >
          </a-col>
          <a-col
            :span="7"
            class="info-item"
            style="border-right: 1px solid rgba(158, 164, 185, 0.3)"
          >
            <div
              class="info-item-detail left one_line"
              v-if="version == 'high'"
            >
              <i class="bg kh"></i>
              完成考核任务：{{ homeCount.assessCompleteMissionCount || 0 }}/{{
                homeCount.assessMissionCount || 0
              }}
            </div>
            <div class="info-item-detail left one_line">
              <i class="bg lj"></i>
              累计得分/总分：{{
                homeCount.exerciseScore +
                  homeCount.assessScore +
                  homeCount.testScore || 0
              }}/{{ homeCount.totalScore || 0 }}({{
                homeCount.totalScore == 0
                  ? "0"
                  : parseFloat(
                      ((homeCount.exerciseScore +
                        homeCount.assessScore +
                        homeCount.testScore) /
                        homeCount.totalScore) *
                        100
                    ).toFixed(2)
              }}%)
            </div>
            <div class="info-item-detail left one_line">
              <i class="bg lx"></i>
              完成基础练习：{{ homeCount.completeCaseCount || 0 }}/{{
                homeCount.caseCount || 0
              }}
            </div>
            <div
              class="info-item-detail left one_line"
              v-if="version == 'middle' || version == 'high'"
            >
              <i class="bg rw"></i>
              完成练习任务：{{ homeCount.exerciseCompleteMissionCount || 0 }}/{{
                homeCount.exerciseCompleteMissionCount || 0
              }}
            </div>
          </a-col>

          <a-col :span="4" class="info-item">
            <router-link to="/home" class="route-link-view"
              >我的基础练习</router-link
            >
            <p class="route-link-tip" style="margin-top: 10px">
              可显示所有案例
            </p>
            <p class="route-link-tip">
              得分/总分：{{ homeCount.testScore || 0 }}/{{
                homeCount.testTotalScore || 0
              }}({{
                homeCount.testTotalScore == 0
                  ? "0"
                  : parseFloat(
                      (homeCount.testScore / homeCount.testTotalScore) * 100
                    ).toFixed(2)
              }}%)
            </p>
          </a-col>
          <a-col
            :span="4"
            class="info-item"
            v-if="version == 'middle' || version == 'high'"
          >
            <router-link to="/home/my-practise-task" class="route-link-view"
              >我的练习任务</router-link
            >
            <p class="route-link-tip" style="margin-top: 10px">
              完成了{{ homeCount.exerciseCompleteMissionCount }}个练习任务
            </p>
            <p class="route-link-tip">
              得分/总分：{{ homeCount.exerciseScore || 0 }}/{{
                homeCount.exerciseTotalScore || 0
              }}({{
                homeCount.exerciseTotalScore == 0
                  ? "0"
                  : parseFloat(
                      (homeCount.exerciseScore / homeCount.exerciseTotalScore) *
                        100
                    ).toFixed(2)
              }}%)
            </p>
          </a-col>
          <a-col :span="4" class="info-item" v-if="version == 'high'">
            <router-link to="/home/my-assessment-task" class="route-link-view"
              >我的考核任务</router-link
            >
            <p class="route-link-tip" style="margin-top: 10px">
              参与了{{ homeCount.assessCompleteMissionCount }}个考核任务
            </p>
            <p class="route-link-tip">
              得分/总分：{{ homeCount.assessScore || 0 }}/{{
                homeCount.assessTotalScore || 0
              }}({{
                homeCount.assessTotalScore == 0
                  ? "0"
                  : parseFloat(
                      (homeCount.assessScore / homeCount.assessTotalScore) * 100
                    ).toFixed(2)
              }}%)
            </p>
          </a-col>
        </a-row>
      </a-col>
      <!-- <a-col :span="12" class="content-right">
        <IndexChart />
      </a-col> -->
    </a-row>
    <div
      style="
        color: rgb(177, 176, 183);
        width: 100%;
        text-align: center;
        height: 35px;
        background: #fff;
        line-height: 40px;
      "
    >
      Copyright©2013-2021hst.com All Rights Reserved临床思维模拟训练版权所有
    </div>
    <!-- <router-link to="/practice?caseId=1">练习</router-link>
        <router-link to="/exma">考试</router-link> -->
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import Header from "@/components/header";
import IndexChart from "./modules/indexChart.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "index",
  components: {
    Header,
    IndexChart,
  },
  data() {
    return {
      homeCount: {},
      version: "low", //low=低版本,middle=中版本,high=高版本
    };
  },
  created() {
    this.getHomeCount();
    this.getMemberClassInfo();
    this.getHospitalVersion();
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    async getHomeCount() {
      try {
        let res = await this.$http.get(this.$interface.homeCount);
        this.homeCount = res.data.data;
        console.log(res.data.data, "res.data.data");
      } catch (err) {}
    },
    async getHospitalVersion() {
      try {
        let res = await this.$http.get("/rest-api/home/hospitalVersion");
        this.version = res.data.data.version;
        localStorage.setItem("Version", JSON.stringify(this.version));
        console.log(this.version, "getHospitalVersion");
      } catch (err) {}
    },
    async getMemberClassInfo() {
      try {
        let res = await this.$http.get(this.$interface.memberClassInfo);
        let _userInfo = this.userInfo || {};
        _userInfo.classInfo = res.data.data || [];
        await this.setUser(_userInfo);
      } catch (err) {}
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style lang="scss">
@import "index.scss";
</style>